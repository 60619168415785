<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="reppath" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->


      <FocusTrap>
        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="310" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">

              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Stock Filters</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

<!--                <div class="form-group row">-->
<!--                  <label class="col-md-3 col-form-label">As On:</label>-->
<!--                  <div class="col-md-9">-->
<!--                    <input id= "txtfrom" type="date" class="form-control"  v-model="ason_date"/>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"   v-model="upto_date" :min="from_date" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Quality</label>
                  <div class="col-md-9">
                    <select ref="el_bag" class="form-control"  v-model="quality_id">
                      <option value="0">All</option>
                      <option v-for="quality in qualities" v-bind:value="quality.id">
                        {{ quality.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Color</label>
                  <div class="col-md-9">
                    <select ref="el_bag" class="form-control"  v-model="color_id">
                      <option value="0">All</option>
                      <option v-for="quality in colors" v-bind:value="quality.id">
                        {{ quality.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Report<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'


export default {
  name: "knitting_qc_report",
  components: {
    ReportViewer,
  },
  store,
  data()  {
    return {
      reppath: '',
      filename: 'knitting_qc_report.mrt',
      ason_date:  moment().format('YYYY-MM-DD'),
      qualities: [],
      colors : [],
      group_id: 0,
      quality_id : 0,
      color_id : 0,
      jsondata : {},
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
    }
  },
  mounted() {
    const self = this;

    self.$modal.show('filter-window');

    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible() {
      this.$data.reppath = '';
      $('#txtfrom').focus();
    },
    showFilterWindow() {
      this.$modal.show('filter-window');
    },
    loadFilterData(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          if(resp.data != null){
            self.$data.qualities = resp.data;
          }
        } else {
          swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {

      });


      self.$data.colors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/colors`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(resp.data != null){
            self.$data.colors = resp.data;
          }
        } else {
          swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {

      });
    },
    loadData(){
      const self = this;
      //alert(`${process.env.VUE_APP_ROOT_API}api/report/stock/knitfabric?from=${self.$data.ason_date}&upto=${self.$data.upto_date}&color=${self.$data.color_id}&quality=${self.$data.quality_id}`);

      self.$modal.hide('filter-window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      
      fetch(`${process.env.VUE_APP_ROOT_API}api/report/qc/knitting_qc?from=${self.$data.from_date}&upto=${self.$data.upto_date}&color=${self.$data.color_id}&quality=${self.$data.quality_id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if(resp.data != null){

            self.$data.jsondata = resp;
            self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/report/qc/knitting_qc?from=${self.$data.from_date}&upto=${self.$data.upto_date}&color=${self.$data.color_id}&quality=${self.$data.quality_id}`;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });


    },
  }

}
</script>

<style scoped>

</style>
